<template>
	<div class="fill-height d-flex flex-column justify-space-between">
		<v-container class="d-flex flex-column align-center">
			<v-card-title
				class="subtitle-2 font-weight-bold flex-column justify-center text-center"
			>
				{{ t("subtitle") }}
			</v-card-title>
			<v-form ref="form">
				<v-row dense>
					<v-col cols="12">
						<TextField
							:rules="emailRules"
							v-model="form.email"
							:title="`${t('email')}*`"
						/>
					</v-col>
					<v-col cols="12">
						<PhoneField
							v-if="copied"
							ref="phoneField"
							v-model="form.phone"
							:messages="t('phoneMessage')"
							:title="`${t('phone')}*`"
						/>
					</v-col>
				</v-row>
			</v-form>
		</v-container>
		<v-container class="mb-3">
			<LayoutBtn :confirm="$t('global.btnSend')" @click-confirm="send" />
		</v-container>
	</div>
</template>

<script>
import LayoutBtn from "@/layouts/LayoutBtn";
import TextField from "@/components/field/TextField";
import PhoneField from "@/components/field/PhoneField";
import { mapFields } from "@/helpers/mapFields";
import { mapActions, mapGetters } from "vuex";
export default {
	components: {
		LayoutBtn,
		TextField,
		PhoneField
	},
	data: vm => ({
		copied: false,
		form: {
			phone: null,
			email: null
		},
		emailRules: [
			v => !!v || vm.$t("global.required"),
			v =>
				!v ||
				/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(v) ||
				vm.$t("global.emailValid")
		]
	}),
	computed: {
		...mapGetters("service", ["getBasePath"]),
		...mapFields({
			fields: ["reservation"],
			base: "checkinModel",
			mutation: "SET_UPDATE_MODEL",
			module: "service"
		})
	},
	created() {
		this.form.email = this.reservation.client.email;
		this.form.phone = this.reservation.client.phone;
		this.copied = true;
	},
	methods: {
		...mapActions("service", ["_updateClient"]),

		t(key, options = {}) {
			return this.$t(`section.contact.${key}`, options);
		},
		async send() {
			if (
				!this.$refs.form.validate() ||
				!this.$refs.phoneField.validate()
			)
				return;
			this._updateClient({
				phone: this.form.phone,
				email: this.form.email
			}).then(({ success }) => {
				if (success) this.$router.push(this.getBasePath("/end"));
			});
		}
	}
};
</script>
